@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&family=Roboto+Mono:wght@400;500;600&display=swap');

$ats-blue: #003478;
$ats-green: #97bb3d;

$black-100: #000000;
$black-200: #1a1a1a;
$black-300: #333333;
$black-400: #666666;

$white-100: #ffffff;
$white-200: #fafafa;
$white-300: #f5f5f5;
$white-400: #e5e5e5;

$blue: #1a7bff;
$green: #33ae74;
$orange: #ff9000;
$red: #da163d;
$yellow: #fff200;

$transition: 0.2s linear all;

@mixin mono-font($size, $weight) {
	font: $weight $size Roboto Mono, monospace;
}

@mixin flex($direction, $justify-content, $align-item) {
	display: flex;
	align-items: $align-item;
	flex-direction: $direction;
	justify-content: $justify-content;
}

@mixin size($width, $height) {
	width: $width;
	height: $height;
}

@mixin center-child {
	display: flex;
	align-items: center;
	justify-content: center;
}

@-webkit-keyframes slider-fade {
	from {
		opacity: 0.25;
	}
	to {
		opacity: 1;
	}
}

@keyframes slider-fade {
	from {
		opacity: 0.25;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

@import '../../init_';

.notifier {
	position: fixed;
	z-index: 9;
	right: 0;
	bottom: 0;
	padding: 20px;

	.notifier-item {
		@include flex(row, unset, center);
		@include size(300px, auto);
		position: relative;
		right: calc(-100% - 20px);
		min-height: 60px;
		margin-top: 10px;
		padding: 10px;
		transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		opacity: 0.5;
		color: $green;
		border-left: 2px solid $green;
		border-radius: 2px;
		background-color: $white-100;
		box-shadow: 0.125px 0.25px 2px transparentize($black-100, 0.8);
		cursor: pointer;

		&.slide-in {
			right: 0;
			opacity: 1;
		}

		&.error {
			color: $red;
			border-left-color: $red;
		}

		&.info {
			color: $blue;
			border-left-color: $blue;
		}

		&.warning {
			color: $orange;
			border-left-color: $orange;
		}

		&--icon {
			color: white;
			pointer-events: none;
		}

		&--text {
			margin-left: 5px;
			text-align: left;
			pointer-events: none;
		}
	}
}

@import '../../init_';

.MuiFormControl-root.MuiTextField-root {
	padding-left: 0.5rem;
	width: 100%;
	height: 35px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
	&::before,
	&::after {
		display: none !important;
	}
}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
	font-family: 'Baloo 2', sans-serif;
}

.MuiFormHelperText-root {
	display: none !important;
}

.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiToolbar-gutters {
	background-color: $ats-blue !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
	> button {
		color: $ats-blue !important;
	}
}

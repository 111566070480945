@import '../../init_';

.input-box {
	@include size(100%, auto);
	position: relative;
	margin-bottom: 2rem;
	border-bottom: 2px solid $white-400;

	&.shrink {
		@include size(calc(275px - 0.5rem), auto);
		display: inline-flex;

		&:nth-child(1),
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(9),
		&:nth-child(13),
		&:nth-child(18),
		&:nth-child(20),
		&:nth-child(21),
		&:nth-child(23) {
			margin-right: 1rem;
		}

		&:nth-child(17),
		&:nth-child(18) {
			padding-left: 0.5rem;
		}
	}

	&.warn {
		&::before {
			opacity: 1;
			background-color: $orange;
			width: 100%;
		}

		> div {
			visibility: visible;
		}
	}

	&[type='date'],
	&[type='time'] {
		> label {
			top: -0.9rem;
			font-size: 0.75rem;
		}
	}

	&[type='checkbox'] {
		border: none;

		&::before {
			display: none;
		}

		> input {
			@include size(1rem, 1rem);
			margin-right: 0.5rem;
			cursor: pointer;
			padding: 0;
		}

		> label {
			position: static;
		}

		> input:focus + label,
		> input:valid + label {
			font-size: 1rem;
		}
	}

	&::before {
		@include size(0, 2px);
		position: absolute;
		bottom: -2px;
		left: 50%;
		transform: translate(-50%);
		transition: $transition;
		opacity: 0;
		background-color: $ats-blue;
		content: '';
	}

	&:focus-within::before {
		opacity: 1;
		width: 100%;
	}

	> input,
	textarea {
		@include size(100%, 100%);
		border: none;
		background: none;
		padding: 0.5rem;
	}

	> textarea {
		@include size(100%, 100px);
		overflow-y: scroll;
		resize: none;

		&:hover::-webkit-scrollbar-thumb {
			background-color: $white-300;
		}
	}

	> input:focus + label,
	> textarea:focus + label {
		color: $ats-blue;
	}

	> input:focus + label,
	> input:valid + label {
		top: -0.74rem;
		font-size: 0.75rem;
	}

	> textarea:focus + label,
	> textarea:valid + label {
		top: -1rem;
		font-size: 0.75rem;
	}

	> label {
		position: absolute;
		top: 0.74rem;
		left: 0.5rem;
		transition: $transition;
		width: -moz-fit-content;
		width: -webkit-fit-content;
		width: fit-content;
		color: $black-400;
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-user-select: none;
		user-select: none;
		pointer-events: none;
	}

	> .ic {
		position: absolute;
		top: 0.74rem;
		right: 0.75rem;
		visibility: hidden;
		cursor: pointer;
		color: $orange;

		&:hover::before,
		&:hover::after {
			transform: scale(1);
		}

		&::before,
		&::after {
			position: absolute;
			transform: scale(0);
			transition: 0.2s ease-in-out;
			z-index: 1;
		}

		&::before {
			top: -180%;
			right: -0.75rem;
			transform-origin: 85% 100%;
			border-radius: 4px;
			box-shadow: 0.5px 0.75px 2px 1px transparentize($black-100, 0.9);
			background-color: $white-100;
			padding: 0.45rem;
			width: -moz-max-content;
			width: -webkit-max-content;
			width: max-content;
			text-shadow: 0.5px 1px 0.5px transparentize($black-100, 0.9);
			color: $orange;
			content: attr(data-message);
		}

		&::after {
			top: -6px;
			right: 0.2rem;
			transform-origin: top;
			border: 5px solid transparent;
			border-top-color: $orange;
			content: '';
		}
	}
}

@import '../../init_';

.main-page {
	@include size(calc(100% - 10px), calc(100% - 60px));
	margin: 5px;
	background-color: white;
	overflow-y: scroll;

	&.sidebar-active {
		.main-page__sidebar {
			left: 0;
		}

		.main-page__section {
			@include size(calc(100% - 380px), auto);
			left: 380px;
			margin: 0;
		}
	}

	&:hover::-webkit-scrollbar-thumb {
		background-color: $white-400;
	}

	&__sidebar {
		@include size(390px, calc(100% - 60px));
		position: fixed;
		top: 60px;
		left: -390px;
		transition: $transition;
		z-index: 8;
		box-shadow: 1px 0 2px transparentize($black-100, 0.9);
		background-color: $white-100;
	}

	&__section {
		@include flex(row, unset, unset);
		@include size(100%, auto);
		position: relative;
		left: 0;
		transition: $transition;
		margin: 0 auto;
		max-width: 1440px;

		> * {
			@include size(50%, auto);
		}

		&--message {
			@include size(100%, calc(100vh - 60px - 10px));
			text-align: center;

			> p {
				margin-top: calc(70px + 64px + 2.5px + 24vh);
			}
		}

		&--preview {
			@include flex(row, flex-end, unset);
			margin: 25px 0 25px 20px;
		}

		&--rmsform {
			margin: 25px 15px 55px 10px;
			min-width: 550px;
		}
	}
}

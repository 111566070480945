@import '../../init_';

.preview {
	max-width: 530px;

	&__slider {
		position: -webkit-sticky;
		position: sticky;
		top: 10px;

		&:hover {
			.preview__slider--navigator {
				left: 15px;
				opacity: 1;

				&.next {
					right: 15px;
					left: unset;
				}
			}
		}

		&--slide {
			&.fade {
				-webkit-animation: slider-fade 0.4s ease;
				animation: slider-fade 0.4s ease;
			}

			.image {
				border: 1px solid $white-400;
				box-shadow: 0.5px 1px 4px transparentize($black-100, 0.9);
				background-color: $white-200;
				cursor: zoom-in;
				width: 100%;
			}

			.count {
				@include mono-font(0.75rem, 400);
				position: absolute;
				bottom: 40px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&--navigator {
			@include center-child();
			@include size(40px, 40px);
			position: absolute;
			top: 50%;
			left: 3px;
			transform: translateY(-50%);
			transition: $transition;
			opacity: 0;
			border: none;
			border-radius: 50%;
			background-color: transparent;
			cursor: pointer;
			color: $black-400;
			font-size: 1.4rem;

			&.next {
				right: 3px;
				left: unset;

				> svg {
					transform: rotate(180deg);
				}
			}

			&:hover {
				border-color: $white-100;
				background-color: transparentize($ats-blue, 0.2);
				color: $white-100;
			}

			&:active {
				background-color: $ats-blue;
			}
		}

		&--indicator {
			margin: 5px auto 0 auto;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;

			> .dot {
				@include size(20px, 8px);
				display: inline-block;
				transition: $transition;
				margin-right: 5px;
				border: 1px solid $white-400;
				border-radius: 2px;
				background-color: $white-200;
				cursor: pointer;

				&:last-child {
					margin: 0;
				}

				&:hover {
					transform: scale(1.1);
					background-color: transparentize($ats-blue, 0.25);
				}

				&.active {
					transform: scale(1.1);
					border-color: $ats-blue;
					background-color: $ats-blue;
				}
			}
		}
	}

	.image-modal {
		position: fixed;
		transition: $transition;
		opacity: 0;
		z-index: 9;
		background-color: transparentize($black-100, 0.95);
		pointer-events: none;
		inset: 0 0 0 0;

		&.active {
			opacity: 1;
			pointer-events: all;
		}

		div {
			div {
				&.action {
					> div {
						transition: $transition;

						&:first-child:hover > svg {
							fill: $white-100;
						}

						&:hover {
							background-color: $ats-blue;

							svg,
							svg > * {
								stroke: $white-100;
							}
						}
					}
				}

				div {
					@include center-child();

					img {
						margin-top: 10px;
						box-shadow: 0 0 2px transparentize($black-100, 0.8);
						width: unset !important;
						height: calc(100vh - 20px);
					}
				}
			}
		}

		&--navigator {
			@include center-child();
			@include size(40px, 40px);
			position: fixed;
			top: 50%;
			left: 10%;
			transform: translateY(-50%);
			transition: $transition;
			z-index: 9;
			border: none;
			border-radius: 50%;
			box-shadow: 0 0 2px transparentize($black-100, 0.8);
			background-color: transparentize($black-400, 0.6);
			cursor: pointer;
			color: $white-100;
			font-size: 1.4rem;

			&.next {
				right: 10%;
				left: unset;

				> svg {
					transform: rotate(180deg);
				}
			}

			&:hover {
				border-color: $white-100;
				background-color: transparentize($ats-blue, 0.2);
				color: $white-100;
			}

			&:active {
				background-color: $ats-blue;
			}
		}

		&--open-in-new-window {
			@include center-child();
			@include size(auto, 38px);
			position: absolute;
			top: 10px;
			left: 10px;
			transition: $transition;
			border: 1px solid transparent;
			border-radius: 2px;
			box-shadow: 0.5px 1px 4px rgba(0, 0, 0, 0.2);
			background-color: $white-100;
			cursor: pointer;
			padding: 0 10px;

			&:hover {
				background-color: $ats-blue;
				color: $white-100;
			}

			&:active {
				background-color: darken($ats-blue, 5%);
				color: $white-100;
			}
		}
	}
}

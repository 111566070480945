@import '../../init_';

.header {
	@include size(100%, 60px);
	z-index: 9;
	box-shadow: 0 1px 2px transparentize($black-100, 0.9);
	background-color: $white-100;
	min-height: 60px;

	&-container {
		@include flex(row, unset, center);
		@include size(100%, 100%);
		margin: 0 auto;
		padding: 0 20px;
		max-width: 1440px;
	}

	&--logo {
		@include size(auto, 44px);

		> a {
			@include flex(row, unset, center);

			> img {
				@include size(160px, 44px);
			}

			> span {
				color: $ats-blue;
				font-size: 1.25rem;
			}
		}
	}

	&__action {
		@include flex(row, unset, center);
		flex-grow: 1;
		margin-left: 20px;

		&--upload {
			@include flex(row, center, center);
			@include size(auto, 34px);
			border: 1px solid transparent;
			border-radius: 5px;
			background-color: $green;
			cursor: pointer;
			padding: 0 5px;
			min-width: 34px;
			color: $white-100;

			&.spin {
				> svg {
					-webkit-animation: spin 0.8s linear infinite;
					animation: spin 0.8s linear infinite;
				}
			}

			&:focus,
			&:hover {
				background-color: lighten($green, 5%);
			}

			&:active {
				background-color: darken($green, 5%);
			}

			> svg {
				font-size: 1.25rem;
			}

			> span {
				margin-left: 5px;
			}
		}

		&--search {
			position: relative;
			margin-left: 10px;

			&:focus-within {
				> input {
					border-color: $ats-blue;
					box-shadow: 0 0 4px 1px transparentize($ats-blue, 0.6);
					background-color: $white-100;
				}

				> svg {
					color: $ats-blue;
				}
			}

			> input {
				@include size(195px, 34px);
				transition: $transition;
				border: 1px solid $white-400;
				border-radius: 100px;
				box-shadow: 0 0 6px transparent;
				background-color: $white-200;
				padding: 0 10px 0 30px;
			}

			> svg {
				position: absolute;
				top: 8.5px;
				left: 9.5px;
				opacity: 0.8;
				color: $black-400;
			}
		}
	}

	&__user {
		text-shadow: 0 4px 4px transparentize($black-100, 0.8);

		> button {
			border: none;
			background: none;
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

@import '../../init_';

.auth-page {
	@include size(100%, 100%);
	background-color: $white-200;

	&-container {
		@include flex(column, flex-start, center);
		margin: 0 auto;
		padding-top: 3rem;
		max-width: 340px;
	}

	&__header {
		@include flex(column, flex-start, center);

		&--logo {
			@include center-child();
			border: 2px solid transparent;
			border-radius: 50%;
			overflow: hidden;
			-webkit-filter: drop-shadow(0.5px 1px 0px transparentize($black-100, 0.6));
			filter: drop-shadow(0.5px 1px 0px transparentize($black-100, 0.6));

			> img {
				@include size(60px, 60px);
			}
		}

		&--text {
			margin-top: 1rem;

			> p {
				text-shadow: 0.5px 1px 1px transparentize($black-100, 0.8);
				color: $black-200;
				font-size: 1.25rem;
			}
		}
	}

	&__form {
		@include size(100%, auto);
		margin-top: 1.5rem;

		&--main {
			border: 1px solid $white-400;
			border-radius: 6px;
			box-shadow: 0 1px 1px transparentize($black-100, 0.85), 0 10px 0 -6px $white-100,
				0 10px 1px -5px transparentize($black-100, 0.85), 0 20px 0 -12px $white-100,
				0 20px 1px -11px transparentize($black-100, 0.85);
			background-color: $white-100;
			padding: 1.25rem;

			.message {
				@include center-child();
				@include size(100%, 0);
				transition: $transition;
				opacity: 0;
				margin-bottom: 0.5rem;
				border-radius: 5px;
				background-color: $red;
				text-shadow: 0.5px 1px 1px transparentize($black-100, 0.8);
				color: $white-100;

				&.show {
					opacity: 1;
					margin-bottom: 1rem;
					height: 36px;
				}
			}

			.input-container {
				position: relative;
				margin-bottom: 1rem;

				> input {
					@include size(100%, 36px);
					transition: $transition;
					margin-top: 0.5rem;
					border: 1px solid $white-400;
					border-radius: 5px;
					background-color: $white-200;
					padding: 0.5rem;

					&:focus {
						border-color: $ats-blue;
						box-shadow: 0 0 4px 1px transparentize($ats-blue, 0.6);
						background-color: $white-100;
					}

					&.error {
						border-color: $red;

						&:focus {
							box-shadow: 0 0 4px 1px transparentize($red, 0.6);
						}
					}
				}
			}

			button[type='submit'] {
				@include size(100%, 36px);
				position: relative;
				transition: $transition;
				margin: 1rem 0;
				border: none;
				border-radius: 5px;
				background-color: $green;
				cursor: pointer;
				color: $white-100;

				&::before {
					position: absolute;
					transition: inherit;
					border: 1px solid transparent;
					border-radius: 8px;
					box-shadow: 0 0 8px transparentize($green, 1), inset 0 0 8px transparentize($green, 1);
					content: '';
					inset: 0;
				}

				&:focus,
				&:hover {
					background-color: lighten($green, 5%);

					&::before {
						border-color: $green;
						box-shadow: 0 0 8px transparentize($green, 0.4),
							inset 0 0 8px transparentize($green, 0.4);
						inset: -4px;
					}
				}

				&:active {
					background-color: darken($green, 5%);

					&::before {
						inset: 0;
					}
				}

				&:disabled {
					cursor: progress;

					&::before {
						display: none;
					}

					> svg {
						-webkit-animation: spin 0.8s linear infinite;
						animation: spin 0.8s linear infinite;
					}
				}
			}
		}
	}

	#crd {
		margin-top: 3rem;
		color: $black-400;
		font-size: 0.9em;
	}
}

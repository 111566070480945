@import '../../init_';

.sidebar {
	@include flex(column, unset, unset);
	@include size(100%, 100%);
	padding: 5px;

	&.active {
		.sidebar__header--tools {
			> button {
				transform: rotateY(0);
				margin-right: 0;
				width: 38px;
			}
		}
	}

	&__header {
		@include flex(row, space-between, center);
		padding: 20px 15px 20px 15px;

		&--title {
			color: $ats-blue;
			font-size: 1.4rem;
		}

		&--tools {
			@include flex(row, unset, center);

			> .refresh-btn {
				@include center-child();
				@include size(20px, 30px);
				background: none;
				font-size: 2rem;
				margin-right: 0.5rem;
				border: none;
				cursor: pointer;

				&:hover > svg {
					transform: rotate(360deg);
				}

				> svg {
					@include size(20px, 20px);
					transition: 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
					transform: rotate(0);
				}
			}

			#records_status {
				@include center-child();
				@include size(125px, 30px);
				position: relative;
				border-radius: 5px;
				background-color: $white-300;

				> select {
					border: none;
					background: none;
					cursor: pointer;
					text-align: center;

					option {
						background-color: $white-300;
					}

					&::-ms-expand {
						display: none;
					}
				}
			}

			> .toggle-btn {
				@include center-child();
				@include size(20px, 30px);
				transform: rotateY(180deg);
				transition: $transition;
				background: none;
				font-size: 2rem;
				margin-right: -45px;
				margin-left: 0.5rem;
				border: none;
				cursor: pointer;

				> svg {
					@include size(20px, 20px);
				}
			}
		}
	}

	&__search {
		z-index: 1;
		padding: 0 15px 5px 15px;
		height: 64px;

		&--input {
			@include flex(row, unset, unset);
			gap: 0.5rem;

			> .search-box {
				@include flex(row, unset, unset);
				transition: $transition;
				position: relative;
				width: 100%;
				border: 1px solid $white-400;
				border-radius: 5px;
				box-shadow: 0 0 6px transparent;
				background-color: $white-200;
				overflow: hidden;

				&:focus-within {
					border-color: $ats-blue;
					box-shadow: 0 0 4px 1px transparentize($ats-blue, 0.6);
					background-color: $white-100;

					> svg {
						color: $ats-blue;
					}
				}

				> input {
					@include size(calc(100% - 48px), 38px);
					padding: 0 10px 0 30px;
					border: none;
				}

				> svg {
					position: absolute;
					top: 10.5px;
					left: 9px;
					opacity: 0.8;
					color: $black-400;
					cursor: pointer;
				}

				> div {
					@include flex(row, center, center);
					@include size(48px, 100%);
					background-color: #f5f5f5;
					top: 0;
					right: 0;
					gap: 0.15rem;
					cursor: pointer;

					> * {
						cursor: pointer;
					}

					> label {
						font-size: 0.8rem;
						padding-top: 2.5px;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
					}
				}
			}

			> .sorting-box {
				> select {
					@include size(115px, 40px);
					transition: $transition;
					background-color: #fff;
					background-image: url('../../assets/BiSort.svg');
					background-position: right 0.5rem center;
					background-repeat: no-repeat;
					background-size: 1rem 1rem;
					-webkit-print-color-adjust: exact;
					color-adjust: exact;
					font-size: 0.8rem;
					border: 1px solid $white-400;
					border-radius: 5px;
					box-shadow: 0 0 6px transparent;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					padding: 0.5rem;
					cursor: pointer;

					&:focus {
						border-color: $ats-blue;
						box-shadow: 0 0 4px 1px transparentize($ats-blue, 0.6);
						background-color: $white-100;
					}
				}
			}
		}

		&--toast {
			margin-top: 5px;

			> span {
				color: $black-400;
				font-size: 0.9rem;
			}
		}
	}

	&__result {
		@include size(100%, calc(100% - 70px - 64px));
		padding: 0.5rem 0 0.5rem 15px;
		overflow-y: scroll;
		scroll-behavior: smooth;

		&:hover {
			&::-webkit-scrollbar-thumb {
				background-color: $white-300;
			}
		}

		.show-more {
			@include size(100%, auto);
			transition: $transition;
			opacity: 0.5;
			margin: 1rem 0;
			border: none;
			background: none;
			cursor: pointer;
			text-align: center;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			&.spin {
				pointer-events: none;

				> svg {
					-webkit-animation: spin 0.8s linear infinite;
					animation: spin 0.8s linear infinite;
				}
			}

			&.end:hover {
				opacity: 0.5;
				cursor: default;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}

@import '../../init_';

.list-item {
	@include flex(row, space-between, center);
	@include size(calc(100% - 5px), auto);
	position: relative;
	transition: $transition;
	margin-bottom: 10px;
	border-radius: 5px;
	background-color: $white-200;
	padding: 10px;
	min-height: 60px;

	&.active {
		transform: scale(1.01);
		box-shadow: 0.5px 1px 3px transparentize($black-100, 0.5);
		background-color: $ats-blue;
		color: $white-100;

		> div > .rider_info > .data {
			background-color: transparentize(darken($white-100, 7.5%), 0.4);
			color: $white-100;
		}

		> span {
			box-shadow: 2.5px 2.5px 7.5px darken($ats-blue, 5%), -2.5px -2.5px 7.5px $ats-blue;
		}

		> button,
		> button:focus,
		> button:hover {
			border: none;
			background-color: $green;
			cursor: default;
		}
	}

	> div {
		@include size(calc(100% - 40px - 10px - 20px), 100%);

		.rider_name {
			margin-bottom: 0.3rem;
			font-size: 1.1rem;
		}

		.rider_info {
			> .data {
				@include mono-font(0.8rem, 400);
				transition: $transition;
				border-radius: 0.4rem;
				background-color: transparentize(darken($white-100, 7.5%), 0);
				padding: 0.125rem 0.5rem;
				color: $black-200;
			}

			> .divider {
				opacity: 0.25;
			}
		}
	}

	> span {
		@include center-child();
		@include size(20px, 20px);
		position: absolute;
		right: calc(40px + 15px);
		transition: $transition;
		border-radius: 50%;
		background: linear-gradient(145deg, darken($ats-blue, 5%), $ats-blue);

		> * {
			color: $white-100;
			font-size: 0.8rem;
		}
	}

	> button {
		@include center-child();
		@include size(40px, 40px);
		transition: $transition;
		border: 1px solid $white-400;
		border-radius: 5px;
		background-color: $white-300;
		cursor: pointer;

		&:focus,
		&:hover {
			border: none;
			background-color: transparentize($ats-blue, 0.2);
			color: $white-100;
		}

		&:active {
			background-color: $ats-blue;
		}

		> svg {
			font-size: 1.2rem;
		}
	}
}

.info {
	margin-top: 24vh;
	text-align: center;
	color: $black-400;
}

@import '../../init_';

.bar-loader {
	@include size(100%, 3px);
	position: fixed;
	top: 0;
	left: 0;
	transition: $transition;
	opacity: 0;
	pointer-events: none;

	&.active {
		opacity: 1;
	}

	> span {
		display: block;
		position: absolute;
		border-radius: 2px;
		background-clip: padding-box;
		background-color: $blue;
		height: inherit;
		-webkit-animation: 2.4s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running
			animation-1dkbetu;
		animation: 2.4s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running
			animation-1dkbetu;
		will-change: left, right;

		&:last-child {
			-webkit-animation: 2.4s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running
				animation-1rt1w7v;
			animation: 2.4s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running
				animation-1rt1w7v;
		}
	}
}

@-webkit-keyframes animation-1dkbetu {
	0% {
		right: 100%;
		left: -35%;
	}
	60% {
		right: -90%;
		left: 100%;
	}
	100% {
		right: -90%;
		left: 100%;
	}
}

@keyframes animation-1dkbetu {
	0% {
		right: 100%;
		left: -35%;
	}
	60% {
		right: -90%;
		left: 100%;
	}
	100% {
		right: -90%;
		left: 100%;
	}
}

@-webkit-keyframes animation-1rt1w7v {
	0% {
		right: 100%;
		left: -200%;
	}
	60% {
		right: -8%;
		left: 107%;
	}
	100% {
		right: -8%;
		left: 107%;
	}
}

@keyframes animation-1rt1w7v {
	0% {
		right: 100%;
		left: -200%;
	}
	60% {
		right: -8%;
		left: 107%;
	}
	100% {
		right: -8%;
		left: 107%;
	}
}

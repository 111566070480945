@import '../../init_';

.rmsform {
	form {
		max-width: 550px;

		> .action {
			@include flex(row, space-between, center);
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			> div {
				display: flex;
				gap: 10px;

				button {
					@include center-child();
					@include size(100px, 30px);
					transition: $transition;
					border: none;
					border-radius: 5px;
					cursor: pointer;

					&#confirm {
						background-color: $green;
						color: $white-100;

						&:focus,
						&:hover {
							background-color: lighten($green, 5%);
						}

						&:active {
							background-color: darken($green, 5%);
						}
					}

					&#reject {
						background: none;
						color: transparentize($red, 0.5);

						&:focus,
						&:hover {
							color: $red;
						}

						&:active {
							color: darken($red, 5%);
						}
					}

					&:disabled {
						cursor: progress;

						&.progress {
							> span {
								display: none;
							}

							> svg {
								display: inline;
								-webkit-animation: spin 0.8s linear infinite;
								animation: spin 0.8s linear infinite;
							}
						}
					}

					> svg {
						display: none;
					}
				}
			}

			.consult_status {
				transition: $transition;
				color: $ats-green;

				&.PENDING {
					color: $blue;
				}

				&.ERROR {
					color: $red;
				}

				&.REJECTED {
					color: $orange;
				}

				&.VERIFIED {
					color: $green;
				}
			}
		}
	}
}

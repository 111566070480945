@import './init_';

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;
	color: inherit;
	font: inherit;
}

*:disabled {
	cursor: not-allowed !important;
}

::-webkit-scrollbar {
	background-color: $white-100;
	cursor: pointer;
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: $white-100;
}

::-webkit-scrollbar-thumb {
	border: 1px solid $white-100;
	border-radius: 100px;
	background-color: transparent;

	&:hover {
		border: 0 solid $white-100;
	}
}

::-moz-selection {
	background-color: transparentize($ats-blue, 0.2);
	color: $white-100;
}

::selection {
	background-color: transparentize($ats-blue, 0.2);
	color: $white-100;
}

:root,
body {
	position: absolute;
	overflow: hidden;
	color: $black-200;
	font: 400 1rem/100% 'Baloo 2', cursive;
	inset: 0 0 0 0;
}

#app {
	@include flex(column, unset, unset);
	@include size(100%, 100%);
	background-color: $white-100;
}

.app-info {
	@include mono-font(0.6rem, 400);
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 99999;
	border-top-right-radius: 5px;
	background-color: darken($white-100, 2.5%);
	padding: 5px;
	color: transparentize(black, 0.5);
}
